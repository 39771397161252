/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Toast as RToast, ToastHeader, ToastBody } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Toast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  render() {
    const { header, body, icon, position, color } = this.props;
    const wrapperPos = position.indexOf('top') === 0 ? 'top' : 'bottom';

    const toggle = () => this.setState((p) => ({ show: !p.show }));

    return (
      <div className={`toast-wrapper toast-wrapper-${wrapperPos}`}>
        <RToast isOpen={this.state.show} className={`toast-${position} toast-${color}`}>
          {header && (
            <ToastHeader icon={icon || null} toggle={toggle} className={`toast-header-${color}`}>
              {header}
            </ToastHeader>
          )}
          <ToastBody>{body}</ToastBody>
        </RToast>
      </div>
    );
  }
}

Toast.propTypes = {
  /** Header */
  header: PropTypes.node,
  /** Body */
  body: PropTypes.node.isRequired,
  /** Icon (Depends on theme colors) */
  icon: PropTypes.string,
  /** Position (top-right, top-center, top-left, bottom-right, bottom-center, bottom-left) */
  position: PropTypes.string,
  /** Color (Depends on theme colors) --- Pre-defined colors: primary, secondary, success, info, warning, danger, light, dark */
  color: PropTypes.string,
};

Toast.defaultProps = {
  position: 'top-right',
  color: 'light',
};

export default Toast;
