/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Form as RForm, FormGroup, Label, Input, FormText, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Form extends React.Component {
  render() {
    return (
      <RForm {...this.props} noValidate>
        {this.props.children}
      </RForm>
    );
  }
}

Form.propTypes = {
  children: PropTypes.any,
};

Form.Group = (props) => <FormGroup {...props}>{props.children}</FormGroup>;

Form.Group.propTypes = {
  children: PropTypes.any,
};

Form.Label = (props) => <Label {...props}>{props.children}</Label>;

Form.Label.propTypes = {
  children: PropTypes.any,
};

Form.Input = (props) => <Input {...props}>{props.children}</Input>;

Form.Input.propTypes = {
  children: PropTypes.any,
};

Form.Text = (props) => <FormText {...props}>{props.children}</FormText>;

Form.Text.propTypes = {
  children: PropTypes.any,
};

Form.InputGroup = (props) => <InputGroup {...props}>{props.children}</InputGroup>;

Form.InputGroup.propTypes = {
  children: PropTypes.any,
};

Form.InputGroupAddon = (props) => <InputGroupAddon {...props}>{props.children}</InputGroupAddon>;

Form.InputGroupAddon.propTypes = {
  children: PropTypes.any,
};

Form.InputGroupText = (props) => <InputGroupText {...props}>{props.children}</InputGroupText>;

Form.InputGroupText.propTypes = {
  children: PropTypes.any,
};

export default Form;
