/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as RDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Dropdown extends React.Component {
  render() {
    const { uncontrolled, ...props } = this.props;
    if (uncontrolled) {
      return <UncontrolledDropdown {...props}>{this.props.children}</UncontrolledDropdown>;
    }
    return <RDropdown {...props}>{this.props.children}</RDropdown>;
  }
}

Dropdown.propTypes = {
  children: PropTypes.any,
  uncontrolled: PropTypes.bool,
};

Dropdown.defaultProps = {
  uncontrolled: false,
};

Dropdown.Toggle = (props) => <DropdownToggle {...props}>{props.children}</DropdownToggle>;

Dropdown.Toggle.propTypes = {
  children: PropTypes.any,
};

Dropdown.Menu = (props) => <DropdownMenu {...props}>{props.children}</DropdownMenu>;

Dropdown.Menu.propTypes = {
  children: PropTypes.any,
};

Dropdown.Item = (props) => <DropdownItem {...props}>{props.children}</DropdownItem>;

Dropdown.Item.propTypes = {
  children: PropTypes.any,
};

export default Dropdown;
