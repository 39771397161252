/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card as RCard,
  CardImg,
  CardImgOverlay,
  CardText,
  CardLink,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  CardFooter,
  CardGroup,
  CardDeck,
  CardColumns,
} from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Card extends React.Component {
  render() {
    return <RCard {...this.props}>{this.props.children}</RCard>;
  }
}

Card.propTypes = {
  children: PropTypes.any,
};

Card.Img = (props) => <CardImg {...props}>{props.children}</CardImg>;

Card.Img.propTypes = {
  children: PropTypes.any,
};

Card.ImgOverlay = (props) => <CardImgOverlay {...props}>{props.children}</CardImgOverlay>;

Card.ImgOverlay.propTypes = {
  children: PropTypes.any,
};

Card.Text = (props) => <CardText {...props}>{props.children}</CardText>;

Card.Text.propTypes = {
  children: PropTypes.any,
};

Card.Link = (props) => <CardLink {...props}>{props.children}</CardLink>;

Card.Link.propTypes = {
  children: PropTypes.any,
};

Card.Body = (props) => <CardBody {...props}>{props.children}</CardBody>;

Card.Body.propTypes = {
  children: PropTypes.any,
};

Card.Title = (props) => <CardTitle {...props}>{props.children}</CardTitle>;

Card.Title.propTypes = {
  children: PropTypes.any,
};

Card.Subtitle = (props) => <CardSubtitle {...props}>{props.children}</CardSubtitle>;

Card.Subtitle.propTypes = {
  children: PropTypes.any,
};

Card.Header = (props) => <CardHeader {...props}>{props.children}</CardHeader>;

Card.Header.propTypes = {
  children: PropTypes.any,
};

Card.Footer = (props) => <CardFooter {...props}>{props.children}</CardFooter>;

Card.Footer.propTypes = {
  children: PropTypes.any,
};

Card.Group = (props) => <CardGroup {...props}>{props.children}</CardGroup>;

Card.Group.propTypes = {
  children: PropTypes.any,
};

Card.Deck = (props) => <CardDeck {...props}>{props.children}</CardDeck>;

Card.Deck.propTypes = {
  children: PropTypes.any,
};

Card.Columns = (props) => <CardColumns {...props}>{props.children}</CardColumns>;

Card.Columns.propTypes = {
  children: PropTypes.any,
};

export default Card;
