/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Button as RButton, ButtonDropdown, ButtonGroup, ButtonToolbar } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Button extends React.Component {
  render() {
    return <RButton {...this.props}>{this.props.children}</RButton>;
  }
}

Button.propTypes = {
  children: PropTypes.any,
};

Button.Dropdown = (props) => <ButtonDropdown {...props}>{props.children}</ButtonDropdown>;

Button.Dropdown.propTypes = {
  children: PropTypes.any,
};

Button.Group = (props) => <ButtonGroup {...props}>{props.children}</ButtonGroup>;

Button.Group.propTypes = {
  children: PropTypes.any,
};

Button.Toolbar = (props) => <ButtonToolbar {...props}>{props.children}</ButtonToolbar>;

Button.Toolbar.propTypes = {
  children: PropTypes.any,
};

export default Button;
