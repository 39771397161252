/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Jumbotron as RJumbotron } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Jumbotron extends React.Component {
  render() {
    return <RJumbotron {...this.props}>{this.props.children}</RJumbotron>;
  }
}

Jumbotron.propTypes = {
  children: PropTypes.any,
};

export default Jumbotron;
