import React from 'react';
import PropTypes from 'prop-types';
import VanillaTilt from './source';

export class Tilt extends React.Component {
  constructor(props) {
    super(props);

    this.tilt = React.createRef();
  }

  componentDidMount() {
    if (this.tilt.current) {
      VanillaTilt.init(this.tilt.current, {
        reverse: this.props.reverse,
        max: this.props.max,
        startX: this.props.startX,
        startY: this.props.startY,
        perspective: this.props.perspective,
        scale: this.props.scale,
        speed: this.props.speed,
        transition: this.props.transition,
        axis: this.props.axis,
        reset: this.props.reset,
        easing: this.props.easing,
        glare: this.props.glare,
        maxGlare: this.props.maxGlare,
        gyroscope: this.props.gyroscope,
        gyroscopeMinAngleX: this.props.gyroscopeMinAngleX,
        gyroscopeMaxAngleX: this.props.gyroscopeMaxAngleX,
        gyroscopeMinAngleY: this.props.gyroscopeMinAngleY,
        gyroscopeMaxAngleY: this.props.gyroscopeMaxAngleY,
      });
    }
  }

  componentWillUnmount() {
    this.tilt.current.vanillaTilt.destroy();
  }

  render() {
    let className = this.props.className ? `tilt ${this.props.className}` : 'tilt';
    className = this.props.parallax ? `${className} tilt-parallax` : className;

    return (
      <div className="tilt-wrapper">
        {this.props.href ? (
          <a ref={this.tilt} href={this.props.href} className={className}>
            {this.props.children}
          </a>
        ) : (
          <div ref={this.tilt} className={className}>
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

Tilt.propTypes = {
  /** Css class name */
  className: PropTypes.string,
  /** Url */
  href: PropTypes.any,
  /** Parallax effect */
  parallax: PropTypes.bool,
  /** Reverse the tilt direction */
  reverse: PropTypes.bool,
  /** Max tilt rotation (degrees) */
  max: PropTypes.number,
  /** Starting tilt on the X axis, in degrees. */
  startX: PropTypes.number,
  /** Starting tilt on the Y axis, in degrees. */
  startY: PropTypes.number,
  /** Transform perspective, the lower the more extreme the tilt gets. */
  perspective: PropTypes.number,
  /** 2 = 200%, 1.5 = 150%, etc.. */
  scale: PropTypes.number,
  /** Speed of the enter/exit transition */
  speed: PropTypes.number,
  /** Set a transition on enter/exit. */
  transition: PropTypes.bool,
  /** What axis should be disabled. Can be X or Y. */
  axis: PropTypes.func,
  /** If the tilt effect has to be reset on exit. */
  reset: PropTypes.bool,
  /** Easing on enter/exit. */
  easing: PropTypes.string,
  /** If it should have a "glare" effect */
  glare: PropTypes.bool,
  /** Maximum "glare" opacity (1 = 100%, 0.5 = 50%) */
  maxGlare: PropTypes.number,
  /** Boolean to enable/disable device orientation detection */
  gyroscope: PropTypes.bool,
  /** This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element */
  gyroscopeMinAngleX: PropTypes.number,
  /** This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element */
  gyroscopeMaxAngleX: PropTypes.number,
  /** This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element */
  gyroscopeMinAngleY: PropTypes.number,
  /** This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element */
  gyroscopeMaxAngleY: PropTypes.number,
  children: PropTypes.object,
};

Tilt.defaultProps = {
  className: '',
  href: null,
  parallax: true,
  reverse: false,
  max: 35,
  startX: 0,
  startY: 0,
  perspective: 1000,
  scale: 1,
  speed: 300,
  transition: true,
  axis: null,
  reset: true,
  easing: 'cubic-bezier(.03,.98,.52,.99)',
  glare: false,
  maxGlare: 1,
  gyroscope: true,
  gyroscopeMinAngleX: -45,
  gyroscopeMaxAngleX: 45,
  gyroscopeMinAngleY: -45,
  gyroscopeMaxAngleY: 45,
};

export default Tilt;
