/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as RSpinner } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Spinner extends React.Component {
  render() {
    return <RSpinner {...this.props}>{this.props.children}</RSpinner>;
  }
}

Spinner.propTypes = {
  children: PropTypes.any,
};

export default Spinner;
