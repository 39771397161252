/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as RTooltip, UncontrolledTooltip } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Tooltip extends React.Component {
  render() {
    const { uncontrolled, ...props } = this.props;
    if (uncontrolled) {
      return <UncontrolledTooltip {...props}>{this.props.children}</UncontrolledTooltip>;
    }
    return <RTooltip {...props}>{this.props.children}</RTooltip>;
  }
}

Tooltip.propTypes = {
  children: PropTypes.any,
  uncontrolled: PropTypes.bool,
};

Tooltip.defaultProps = {
  uncontrolled: false,
};

export default Tooltip;
