/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Tab extends React.Component {
  render() {
    return <TabContent {...this.props}>{this.props.children}</TabContent>;
  }
}

Tab.propTypes = {
  children: PropTypes.any,
};

Tab.Item = (props) => <TabPane {...props}>{props.children}</TabPane>;

Tab.Item.propTypes = {
  children: PropTypes.any,
};

export default Tab;
