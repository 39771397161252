/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';

import { Days, CreateCalendar, CreateCalendarHeader, Format, FormatDate, Parse, AddSubMonth, ShowHide } from './datepicker';

// eslint-disable-next-line react/prefer-stateless-function
class Datepicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.selected || Format(new Date(), this.props.format, this.props.locale),
      pickerYear: this.props.selected ? new Date(Parse(this.props.selected, this.props.format)).getFullYear() : new Date().getFullYear(),
      pickerMonth: this.props.selected ? new Date(Parse(this.props.selected, this.props.format)).getMonth() : new Date().getMonth(),
      pickerYearHandle: false,
      pickerMonthHandle: false,
      pickerPositionLeft: 0,
      clickDay: false,
    };

    this.picker = React.createRef();
    this.input = React.createRef();
    this.yearEl = React.createRef();
    this.monthEl = React.createRef();

    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    const pickerLeft = this.picker.current.offsetWidth / 2 - this.input.current.offsetWidth / 2;
    this.setState({
      pickerPositionLeft: pickerLeft > this.input.current.offsetLeft ? -(this.input.current.offsetWidth / 2) : -(this.picker.current.offsetWidth / 2),
    });

    window.addEventListener('click', this.handleFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleFocus);
  }

  handleFocus(e) {
    let d = e.path
      .map((i) => {
        if (typeof i.getAttribute === 'function') {
          if (i.classList.contains('eteration-datepicker')) {
            return true;
          }
          return false;
        }
        return false;
      })
      .filter((i) => !!i)[0];
    if (this.state.clickDay) {
      d = false;
    }
    if (d) {
      ShowHide('datepicker-wrapper', true);
    } else {
      ShowHide('datepicker-wrapper', false);
      this.setState({
        pickerYearHandle: false,
        pickerMonthHandle: false,
        clickDay: false,
      });
    }
  }

  ClickDays(d) {
    const selected = Format(new Date(d), this.props.format, this.props.locale);
    this.setState({
      selected,
      pickerMonth: new Date(d).getMonth(),
      pickerYear: new Date(d).getFullYear(),
      pickerYearHandle: false,
      pickerMonthHandle: false,
      clickDay: true,
    });
    this.input.current.value = selected;
    this.props.onChange(new Date(d));
  }

  ClickMonth(index) {
    this.monthEl.current.scrollTo(0, index * 34);
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      pickerMonthHandle: !this.state.pickerMonthHandle,
      pickerYearHandle: false,
    });
  }

  ChangeMonth(d) {
    this.setState({ pickerMonth: d.index, pickerMonthHandle: false });
    const p = Parse(this.state.selected, this.props.format);
    const newDate = new Date(p.getDate(), d.index, p.getDate());
    const selected = Format(new Date(newDate), this.props.format, this.props.locale);
    this.setState({ selected });
    this.input.current.value = selected;
    this.props.onChange(new Date(newDate));
  }

  ClickYear(index) {
    this.yearEl.current.scrollTo(0, index * 34);
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      pickerYearHandle: !this.state.pickerYearHandle,
      pickerMonthHandle: false,
    });
  }

  ChangeYear(d) {
    this.setState({ pickerYear: d, pickerYearHandle: false });
    const p = Parse(this.state.selected, this.props.format);
    const newDate = new Date(d, p.getMonth(), p.getDate());
    const selected = Format(new Date(newDate), this.props.format, this.props.locale);
    this.setState({ selected });
    this.input.current.value = selected;
    this.props.onChange(new Date(newDate));
  }

  PrevNextMonth(t) {
    const p = Parse(this.state.selected, this.props.format);
    const newDate = AddSubMonth(t, p);
    const selected = Format(newDate, this.props.format, this.props.locale);
    this.setState({
      selected,
      pickerYear: newDate.getFullYear(),
      pickerMonth: newDate.getMonth(),
    });
    this.input.current.value = selected;
    this.props.onChange(newDate);
  }

  CalendarHeader() {
    const data = CreateCalendarHeader(this.props.yearPrev, this.props.yearNext, this.props.locale);
    const { years, months } = data;

    return (
      <>
        <div>
          <span onClick={() => this.ClickMonth(this.state.pickerMonth)} role="button" tabIndex={0}>
            {months.find((i) => i.index === this.state.pickerMonth).name}
          </span>
          <div className={`datepicker-menu ${this.state.pickerMonthHandle ? 'show' : ''}`}>
            <div className="datepicker-menu-inner" ref={this.monthEl}>
              {months.map((item) => (
                <div
                  key={item.index}
                  className={`${item.index === this.state.pickerMonth ? 'selected' : ''}`}
                  onClick={() => this.ChangeMonth(item)}
                  role="button"
                  tabIndex={0}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <span onClick={() => this.ClickYear(years.indexOf(this.state.pickerYear))} role="button" tabIndex={0}>
            {this.state.pickerYear}
          </span>
          <div className={`datepicker-menu ${this.state.pickerYearHandle ? 'show' : ''}`}>
            <div className="datepicker-menu-inner" ref={this.yearEl}>
              {years.map((item) => (
                <div
                  key={item}
                  className={`${item === this.state.pickerYear ? 'selected' : ''}`}
                  onClick={() => this.ChangeYear(item)}
                  role="button"
                  tabIndex={0}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  Calendar(s) {
    const days = CreateCalendar(s, this.props.format);

    let className = 'prev';
    let classNameCount = 0;
    const selected = FormatDate(s, this.props.format, null, this.props.locale);
    const today = Format(new Date(), this.props.format, null, this.props.locale);
    const d = days.map((item, index) => {
      if (new Date(item).getDate() === 1) {
        className = 'current';
        classNameCount += 1;
      }
      if (classNameCount === 2) {
        className = 'next';
      }
      const curr = Format(item, this.props.format, null, this.props.locale);

      return (
        <span
          key={index}
          className={`${className}${curr === selected ? ' selected' : ''}${curr === today ? ' today' : ''}`}
          onClick={() => this.ClickDays(item)}
          role="button"
          tabIndex={0}
        >
          {new Date(item).getDate()}
        </span>
      );
    });
    return chunk(d, 7).map((item, index) => (
      <div key={index} className="weeks">
        {item}
      </div>
    ));
  }

  Picker() {
    return (
      <div className="datepicker" ref={this.picker} style={{ marginLeft: this.state.pickerPositionLeft }}>
        <div className="datepicker-header">
          <div className="left" onClick={() => this.PrevNextMonth('sub')} role="button" tabIndex={0}></div>
          <div className="center">{this.CalendarHeader()}</div>
          <div className="right" onClick={() => this.PrevNextMonth('add')} role="button" tabIndex={0}></div>
        </div>
        <div className="datepicker-days">{Days('min', this.props.locale)}</div>
        <div className="datepicker-calendar">{this.Calendar(this.state.selected)}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="eteration-datepicker">
        {this.props.label && (
          <label htmlFor={this.props.name} className="form-group-label">
            {this.props.label}
          </label>
        )}
        <input id={this.props.name} name={this.props.name} className="form-control" defaultValue={this.state.selected} ref={this.input} />
        <div className={`datepicker-wrapper${this.show ? ' show' : ''}`}>{this.Picker()}</div>
      </div>
    );
  }
}

Datepicker.propTypes = {
  /** Format Date */
  format: PropTypes.string,
  /** Internationalization: en, tr */
  locale: PropTypes.string,
  /** Selected Date */
  selected: PropTypes.string,
  /** Input Label */
  label: PropTypes.string,
  /** Input name */
  name: PropTypes.string,
  /** Return date */
  onChange: PropTypes.func.isRequired,
  /** Past year range */
  yearPrev: PropTypes.number,
  /** Next year range */
  yearNext: PropTypes.number,
};

Datepicker.defaultProps = {
  format: 'dd.MM.yyyy',
  locale: 'tr',
  name: 'datepicker',
  yearPrev: 10,
  yearNext: 10,
};

export default Datepicker;
