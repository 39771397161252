/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Table as RTable } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Table extends React.Component {
  render() {
    return <RTable {...this.props}>{this.props.children}</RTable>;
  }
}

Table.propTypes = {
  children: PropTypes.any,
};

Table.Header = (props) => <thead {...props}>{props.children}</thead>;

Table.Header.propTypes = {
  children: PropTypes.any,
};

Table.Body = (props) => <tbody {...props}>{props.children}</tbody>;

Table.Body.propTypes = {
  children: PropTypes.any,
};

Table.Row = (props) => <tr {...props}>{props.children}</tr>;

Table.Row.propTypes = {
  children: PropTypes.any,
};

Table.Col = (props) => <td {...props}>{props.children}</td>;

Table.Col.propTypes = {
  children: PropTypes.any,
};

Table.ColHeader = (props) => <th {...props}>{props.children}</th>;

Table.ColHeader.propTypes = {
  children: PropTypes.any,
};

export default Table;
