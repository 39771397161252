/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as RPagination, PaginationItem, PaginationLink } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Pagination extends React.Component {
  render() {
    return <RPagination {...this.props}>{this.props.children}</RPagination>;
  }
}

Pagination.propTypes = {
  children: PropTypes.any,
};

Pagination.Item = (props) => <PaginationItem {...props}>{props.children}</PaginationItem>;

Pagination.Item.propTypes = {
  children: PropTypes.any,
};

Pagination.Link = (props) => <PaginationLink {...props}>{props.children}</PaginationLink>;

Pagination.Link.propTypes = {
  children: PropTypes.any,
};

export default Pagination;
