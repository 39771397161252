/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Progress as RProgress } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Progress extends React.Component {
  render() {
    return <RProgress {...this.props}>{this.props.children}</RProgress>;
  }
}

Progress.propTypes = {
  children: PropTypes.any,
};

export default Progress;
