/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Col as RCol } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Col extends React.Component {
  render() {
    return <RCol {...this.props}>{this.props.children}</RCol>;
  }
}

Col.propTypes = {
  children: PropTypes.any,
};

export default Col;
