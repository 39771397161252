/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Playground } from 'docz';

import '../../theme/theme.scss';

export default (props) => (
  <div style={{ margin: '2rem 0' }}>
    <Playground {...props} />
  </div>
);
