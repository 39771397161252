/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { List as RList, ListInlineItem, ListGroup, ListGroupItem } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class List extends React.Component {
  render() {
    return <RList {...this.props}>{this.props.children}</RList>;
  }
}

List.propTypes = {
  children: PropTypes.any,
};

List.Item = (props) => <li {...props}>{props.children}</li>;

List.Item.propTypes = {
  children: PropTypes.any,
};

List.ItemInline = (props) => <ListInlineItem {...props}>{props.children}</ListInlineItem>;

List.ItemInline.propTypes = {
  children: PropTypes.any,
};

List.Group = (props) => <ListGroup {...props}>{props.children}</ListGroup>;

List.Group.propTypes = {
  children: PropTypes.any,
};

List.GroupItem = (props) => <ListGroupItem {...props}>{props.children}</ListGroupItem>;

List.GroupItem.propTypes = {
  children: PropTypes.any,
};

export default List;
