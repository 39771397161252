/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Media as RMedia } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Media extends React.Component {
  render() {
    return <RMedia {...this.props}>{this.props.children}</RMedia>;
  }
}

Media.propTypes = {
  children: PropTypes.any,
};

export default Media;
