/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Collapse as RCollapse, UncontrolledCollapse } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Collapse extends React.Component {
  render() {
    const { uncontrolled, ...props } = this.props;
    if (uncontrolled) {
      return <UncontrolledCollapse {...props}>{this.props.children}</UncontrolledCollapse>;
    }
    return <RCollapse {...props}>{this.props.children}</RCollapse>;
  }
}

Collapse.propTypes = {
  children: PropTypes.any,
  uncontrolled: PropTypes.bool,
};

export default Collapse;
