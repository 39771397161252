/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Nav as RNav, NavItem, NavLink } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Nav extends React.Component {
  render() {
    return <RNav {...this.props}>{this.props.children}</RNav>;
  }
}

Nav.propTypes = {
  children: PropTypes.any,
};

Nav.Item = (props) => <NavItem {...props}>{props.children}</NavItem>;

Nav.Item.propTypes = {
  children: PropTypes.any,
};

Nav.Link = (props) => <NavLink {...props}>{props.children}</NavLink>;

Nav.Link.propTypes = {
  children: PropTypes.any,
};

export default Nav;
