import Playground from 'components/Playground';
import Navbar from "components/Navbar";
import Collapse from "components/Collapse";
import Nav from "components/Nav";
import Dropdown from "components/Dropdown";
import * as React from 'react';
export default {
  Playground,
  Navbar,
  Collapse,
  Nav,
  Dropdown,
  React
};