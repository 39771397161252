/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Navbar as RNavbar, NavbarToggler, NavbarBrand, NavbarText } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Navbar extends React.Component {
  render() {
    return <RNavbar {...this.props}>{this.props.children}</RNavbar>;
  }
}

Navbar.propTypes = {
  children: PropTypes.any,
};

Navbar.Toggler = (props) => <NavbarToggler {...props}>{props.children}</NavbarToggler>;

Navbar.Toggler.propTypes = {
  children: PropTypes.any,
};

Navbar.Brand = (props) => <NavbarBrand {...props}>{props.children}</NavbarBrand>;

Navbar.Brand.propTypes = {
  children: PropTypes.any,
};

Navbar.Text = (props) => <NavbarText {...props}>{props.children}</NavbarText>;

Navbar.Text.propTypes = {
  children: PropTypes.any,
};

export default Navbar;
