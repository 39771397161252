/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as RBreadcrumb, BreadcrumbItem } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Breadcrumb extends React.Component {
  render() {
    return <RBreadcrumb {...this.props}>{this.props.children}</RBreadcrumb>;
  }
}

Breadcrumb.propTypes = {
  children: PropTypes.any,
};

Breadcrumb.Item = (props) => <BreadcrumbItem {...props}>{props.children}</BreadcrumbItem>;

Breadcrumb.Item.propTypes = {
  children: PropTypes.any,
};

export default Breadcrumb;
