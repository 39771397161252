/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as RModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Modal extends React.Component {
  render() {
    return <RModal {...this.props}>{this.props.children}</RModal>;
  }
}

Modal.propTypes = {
  children: PropTypes.any,
};

Modal.Header = (props) => <ModalHeader {...props}>{props.children}</ModalHeader>;

Modal.Header.propTypes = {
  children: PropTypes.any,
};

Modal.Body = (props) => <ModalBody {...props}>{props.children}</ModalBody>;

Modal.Body.propTypes = {
  children: PropTypes.any,
};

Modal.Footer = (props) => <ModalFooter {...props}>{props.children}</ModalFooter>;

Modal.Footer.propTypes = {
  children: PropTypes.any,
};

export default Modal;
