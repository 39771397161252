/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Fade as RFade } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Fade extends React.Component {
  render() {
    return <RFade {...this.props}>{this.props.children}</RFade>;
  }
}

Fade.propTypes = {
  children: PropTypes.any,
};

export default Fade;
