/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover as RPopover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Popover extends React.Component {
  render() {
    const { uncontrolled, ...props } = this.props;
    if (uncontrolled) {
      return <UncontrolledPopover {...props}>{this.props.children}</UncontrolledPopover>;
    }
    return <RPopover {...props}>{this.props.children}</RPopover>;
  }
}

Popover.propTypes = {
  children: PropTypes.any,
  uncontrolled: PropTypes.bool,
};

Popover.defaultProps = {
  uncontrolled: false,
};

Popover.Header = (props) => <PopoverHeader {...props}>{props.children}</PopoverHeader>;

Popover.Header.propTypes = {
  children: PropTypes.any,
};

Popover.Body = (props) => <PopoverBody {...props}>{props.children}</PopoverBody>;

Popover.Body.propTypes = {
  children: PropTypes.any,
};

export default Popover;
