/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Carousel as RCarousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, UncontrolledCarousel } from 'reactstrap';

// eslint-disable-next-line react/prefer-stateless-function
class Carousel extends React.Component {
  render() {
    const { uncontrolled, ...props } = this.props;
    if (uncontrolled) {
      return <UncontrolledCarousel {...props}>{this.props.children}</UncontrolledCarousel>;
    }
    return <RCarousel {...props}>{this.props.children}</RCarousel>;
  }
}

Carousel.propTypes = {
  children: PropTypes.any,
  uncontrolled: PropTypes.bool,
};

Carousel.Item = (props) => <CarouselItem {...props}>{props.children}</CarouselItem>;

Carousel.Item.propTypes = {
  children: PropTypes.any,
};

Carousel.Control = (props) => <CarouselControl {...props}>{props.children}</CarouselControl>;

Carousel.Control.propTypes = {
  children: PropTypes.any,
};

Carousel.Indicators = (props) => <CarouselIndicators {...props}>{props.children}</CarouselIndicators>;

Carousel.Indicators.propTypes = {
  children: PropTypes.any,
};

Carousel.Caption = (props) => <CarouselCaption {...props}>{props.children}</CarouselCaption>;

Carousel.Caption.propTypes = {
  children: PropTypes.any,
};

export default Carousel;
