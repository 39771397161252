import React from 'react';
import { format, parse, getDaysInMonth, getDay, getMonth, getYear, addMonths, subMonths } from 'date-fns';
import { enUS, tr } from 'date-fns/locale';

import { days, months } from './internationalization';

export function ShowHide(className, show) {
  const el = document.getElementsByClassName(className);
  for (let i = 0; i < el.length; i += 1) {
    if (show) {
      el[i].classList.add('show');
    } else {
      el[i].classList.remove('show');
    }
  }
}

export function Days(type, locale) {
  const d = days[locale][type || 'short'];
  return d.map((item) => (
    <span className="days" key={item}>
      {item}
    </span>
  ));
}

export function Months(type, locale) {
  const m = months[locale][type || 'short'];
  return m.map((item) => (
    <span className="months" key={item}>
      {item}
    </span>
  ));
}

export function Format(d, f, l) {
  return format(d, f, { locale: l === 'tr' ? tr : enUS });
}

export function Parse(d, f) {
  return parse(d, f, new Date());
}

export function AddSubMonth(t, d) {
  if (t === 'add') {
    return addMonths(new Date(d), 1);
  }
  return subMonths(new Date(d), 1);
}

// date, format, type, locale
export function FormatDate(d, f, t, l) {
  if (t === 'year') {
    return getYear(new Date(parse(d, f, new Date())));
  }
  if (t === 'month') {
    return getMonth(new Date(parse(d, f, new Date())));
  }
  return format(new Date(parse(d, f, new Date())), f, {
    locale: l === 'tr' ? tr : enUS,
  });
}

export function CreateCalendarHeader(yearPrev, yearNext, locale) {
  const currentYear = new Date().getFullYear();
  const y = [];
  for (let i = currentYear - yearPrev; i <= currentYear + yearNext; i += 1) {
    y.push(i);
  }
  const m = months[locale].full.map((item, index) => ({ name: item, index }));
  return {
    years: y,
    months: m,
  };
}

export function CreateCalendar(d, f) {
  const count = 42;
  const totalDays = [];

  const selected = parse(d, f, new Date());
  const month = getMonth(selected);
  const year = getYear(selected);
  const totalDay = getDaysInMonth(new Date(year, month));
  const startDay = getDay(new Date(year, month, 1)) === 0 ? 6 : getDay(new Date(year, month, 1)) - 1;
  const totalDayPrev = startDay === 1 ? getDaysInMonth(new Date(year, month - 1)) : getDaysInMonth(new Date(year, month - 1)) - startDay + 1;

  if (startDay === 1) {
    totalDays.push(new Date(year, month - 1, totalDayPrev));
  } else {
    for (let i = totalDayPrev; i < totalDayPrev + startDay; i += 1) {
      totalDays.push(new Date(year, month - 1, i));
    }
  }

  for (let i = 1; i <= totalDay; i += 1) {
    totalDays.push(new Date(year, month, i));
  }

  if (count > totalDays.length) {
    const nextDays = count - totalDays.length;
    for (let i = 1; i <= nextDays; i += 1) {
      totalDays.push(new Date(year, month + 1, i));
    }
  }

  return totalDays;
}
